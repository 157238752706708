import * as React from "react";
import { navigate, withPrefix } from "gatsby";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import { isLoggedIn, logout, profiles, getUserFromAccessToken } from "../services/auth";
import { useApplicationContext } from "../../provider";

export default function Header({ valueA, valueB }) {
  const currentUser = typeof window !== "undefined" && JSON.parse(localStorage.getItem("currentUser") || "{}");

  useEffect(() => {
    //this code is just for all pages to check if access_token expired or not
    getUserFromAccessToken();
  }, [])

  const { creditCount } = useApplicationContext();

  const onPricingClick = (e) => {
    e.preventDefault();
    navigate("/pricing-options");
  }

  const onMyCreationsClick = (e) => {
    e.preventDefault();
    window.location.href = "/my-creations";
  }
  const onHelpClick = (e) => {
    e.preventDefault();
    window.location.href = "/help";
  }
  const onSignInClick = (e) => {
    e.preventDefault();
    navigate("/signin");
  }

  useEffect(() => {
    $(".menu-bar-this").click(function () {
      $(".menu-bar-this").removeClass("active");
      $(".nav-bar").slideUp(10);
      $(".overlay").removeClass("show");
      $("body").removeClass("hidden-body");
      if ($(this).next().is(":hidden") == true) {
        $(this).addClass("active");
        $(this).next().slideDown(10);
        $(".overlay").addClass("show");
        $("body").addClass("hidden-body");
      }
    });
  }, []);

  return (
    <header className="header-main" style={{ marginTop: currentUser?.logAs ? '8px': '0px' }}>
      <Helmet>
        <link href={withPrefix("assets/css/bootstrap.min.css")} rel="stylesheet" />
        <link href={withPrefix("assets/css/brands.min.css")} rel="stylesheet" />
        <link href={withPrefix("assets/css/light.min.css")} rel="stylesheet" />
        <link href={withPrefix("assets/css/regular.min.css")} rel="stylesheet" />
        <link href={withPrefix("assets/css/solid.min.css")} rel="stylesheet" />
        <link href={withPrefix("assets/css/duotone.min.css")} rel="stylesheet" />
        <link href={withPrefix("assets/css/fontawesome.min.css")} rel="stylesheet" />
        <link href={withPrefix("assets/css/custom-styles.css")} rel="stylesheet" />
        <title>{`99 Generations | By robots, for humans.™`}</title>
      </Helmet>
      {currentUser?.logAs && <div style={{ borderTop: '8px solid #BB0000', position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1000 }}></div>}
      <div className="container">
        <div className="brand">
          <a href="../" title="99 Generations">
            <img src={withPrefix("img/brand-99-generations.svg")} alt="brand-99-generations" />
          </a>
        </div>
        <div className="main-menu">
          <div className="menu-btn menu-bar-this" id="menu-bar-this1">
            <div className="icon-bar">
              <span />
            </div>
          </div>
          <div className="nav-bar">
            <div className="nav-inn">
              <div className="menu-close menu-bar-this">
                <button className="fal fa-times" />
              </div>
              <nav>
                <ul>
                  <li>
                    <a href="#" onClick={onPricingClick}>Pricing</a>
                  </li>
                  <li>
                    <a href="#" onClick={onMyCreationsClick}>My Creations</a>
                  </li>
                  <li>
                    <a href="#" onClick={onHelpClick}>Help</a>
                  </li>
                </ul>
              </nav>
              <div className="btn-out header-right">
                {isLoggedIn() ? 
                  <a href="#" className="btn btn-gray btn-credits" onClick={profiles}>
                    CREDITS: <span className="count">{creditCount}</span>
                  </a>
                  : <></>
                }
                <a href="#" className="icon-user" onClick={profiles}>
                  {currentUser?.profileImage ? (
                    <div style={{ display: 'flex', height: '100%' }}>
                      <img src={`${currentUser?.profileImage}?version=64`} alt="profile image" style={{ borderRadius: '64px' }} />
                    </div>
                  ) : (
                    <em className="fa fa-user" />
                  )}
                </a>
                {/* <a href="#" className="btn btn-blue">Sign up</a>*/}

                {isLoggedIn() ? (
                  <>
                    <a href="#" className="btn btn-blue" onClick={logout}>
                      Signout
                    </a>
                  </>
                ) : (
                  <>
                    <a href="#" className="btn btn-blue" onClick={onSignInClick}>
                      Get Started
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="user-mbl" onClick={profiles}>
          {currentUser?.profileImage ? (
            <div style={{ display: 'flex', height: '100%' }}>
              <img src={`${currentUser?.profileImage}?version=24`} alt="profile image" style={{ borderRadius: '24px' }} />
            </div>
          ) : (
            <a href="#" className="fa fa-user" />
          )}
        </div>
        <div className="overlay" />
      </div>
    </header>
  )
}